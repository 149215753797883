import './Publications.css';
import data from "../content/Publications.json"
import { Helmet } from 'react-helmet';

interface PublicationProps {
    img: string;
    img_alt: string;
    title: string;
    authors: string;
    link: string;
}

function PublicationItem(props: PublicationProps): JSX.Element{ 
    return (
        <div className='Publication-item'>  
            <img className='Publication-item-img' src={props.img} alt={props.img_alt}></img>
            <div className='Publication-item-container'>
                <h2>{props.title}</h2>
                <p>{props.authors}</p>
                <a href={props.link}>{data.access_text}</a>  
            </div>
        </div>
    )
}

export function Publications(): JSX.Element{

    return (
            <div className="Publications-Container">
                <Helmet>
                  <meta charSet="utf-8" />
                  <title>Charles Emogor | Publication</title>
                  <meta name="description" content='Conservationist and tropical ecologist'></meta>
                  <link rel="canonical" href="/publication" />
                </Helmet>
                <div className="Publications-Container-Items">
                    {data.publications.map((item, index) => {
                        return (
                            <PublicationItem img={item.img} img_alt={item.img_alt} title={item.title} authors={item.authors} link={item.link} />
                        )
                    })}
                </div>
            </div>
    );
}
    