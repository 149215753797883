import { Helmet } from "react-helmet"
import data from "../content/Projects.json"
import './Main.css'
import Linkify from 'react-linkify';

function MyInterests(): JSX.Element {

    let description = data.top.description
    let link_text = data.top.CV_text

    // if (data.top.CV_word !== undefined)
    //   if (data.top.CV_text.includes(data.top.CV_word) && data.top.CV_link !== ""){
    //       description = description + " " + link_text.replace(data.top.CV_word, `<a  href="${data.top.CV_link}" download>${data.top.CV_word}</a>`)
    //   }

    return (
      <div className="MyInterests">
        <div className="MyInterestsContainer-">
          <p dangerouslySetInnerHTML={{ __html: description }} className="bigger NewMain-Container-Start"></p>
        </div>
      </div>
    );
  }

export function Projects(): JSX.Element{
    return(
        <div className='AllProjects-Container'>
              <Helmet>
                  <meta charSet="utf-8" />
                  <title>Charles Emogor | Project</title>
                  <meta name="description" content='Conservationist and tropical ecologist'></meta>
                  <link rel="canonical" href="/project" />
              </Helmet>
                <h1 className="ShowIfMobile">Projects</h1>
                <MyInterests></MyInterests>
                <section className='SectionTitle'></section>
                {data.projects.map((project, index) => {

                    const description = project.description
                    
                    if (project.word_link !== undefined)
                      if (description.includes(project.word_link) && project.link !== ""){
                          project.description = description.replace(project.word_link, `<a  target="_blank" rel="noopener noreferrer" href="${project.link}">${project.word_link}</a>`)
                      }


                    return (
                        <>
                        <div className='Project-Container'>
                            <div className='Project-Container-Description'>
                                <button><h2>{project.title}</h2></button>
                                <Linkify>  
                                  <p>{description}</p>
                                </Linkify>
                            </div>
                            <div className="Project-Container-Images">
                                <div className='Project-Container-Images-All'>
                                {project.img.map((img, index) => {
                                    return (
                                        <img className="Project-Image" key={index} src={img.link}/>
                                    )
                                })}
                                </div>
                                <p><i>Scroll to view more →</i></p>
                            </div>
                        </div>
                          </>
                    )
                })}
            </div>
    )
}