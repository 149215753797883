import {BrowserRouter as Router, Routes, Route, Link} from 'react-router-dom';
import { Header, Footer } from './components/HeaderFooter';
import { Main } from './pages/Main';
import { NewMain } from './pages/NewMain';
import { Projects } from './pages/Projects';
import { Publications } from './pages/Publications';
import { Media } from './pages/Media';
import { Soundscape } from './pages/Soundscape';
import {Helmet} from "react-helmet";


function App() {
  return (
    <Router>
      <Helmet>
          <meta charSet="utf-8" />
          <title>Charles Emogor</title>
          <meta name="description" content='Conservationist and tropical ecologist'></meta>
          <link rel="canonical" href="/" />
      </Helmet>
      <Header/>
        <Routes>
          <Route 
              path="/" 
              element={<NewMain/>}
          />
          <Route 
              path="/project" 
              element={<Projects/>}
          />
          <Route 
              path="/soundscape" 
              element={<Soundscape/>}
          />
          <Route 
              path="/publication" 
              element={<Publications/>}
          />
           <Route 
              path="/media" 
              element={<Media/>}
          />
        </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
