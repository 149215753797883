import './Soundscape.css';
import ReactPlayer from 'react-player'
import data from "../content/Soundscape.json"
import { Helmet } from 'react-helmet';

interface SoundScapeProps {
    url: string;
}

function SoundItem(props: SoundScapeProps): JSX.Element{ 
    return (
        <div className='Soundscape-item'>
            <ReactPlayer 
                url={props.url} 
                width={"300px"}
                height={"300px"}
            />
        </div>
    )
}


export function Soundscape(): JSX.Element{

    return (
        <div className="Soundscape-Background">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Charles Emogor | Soundscape</title>
                <meta name="description" content='Conservationist and tropical ecologist'></meta>
                <link rel="canonical" href="/soundscape" />
            </Helmet>
            <div className="Soundscape-Container">
                <p className="Description-Soundcsapes">{data.description}</p>
                <p>{data.comment}</p>
                <div className="Soundscape-Container-Items">
                    {data.soundscapes.map((sound, index) => {
                        return (
                            <SoundItem url={sound.url}/>
                        )
                    })}
                </div>
            </div>
        </div>
    );
}
    