import "./NewMain.css";
import data from "../content/Projects.json";
import projects from "../content/Projects.json"
import contactdata from "../content/HeaderFooter.json";
import { TypeAnimation } from "react-type-animation";

function Charles(): JSX.Element {
  return (
    <div className="CharlesTyper">
      <TypeAnimation
        // Same String at the start will only be typed once, initially
        cursor={false}
        sequence={[data.top.name, 1000]}
        speed={40} // Custom Speed from 1-99 - Default Speed: 40
        style={{ fontSize: "40px" }}
        wrapper="h1" // Animation will be rendered as a <span>
        repeat={Infinity} // Repeat this Animation Sequence infinitely
      />
      <TypeAnimation
        // Same String at the start will only be typed once, initially
        cursor={false}
        sequence={["", 1500, data.top.title, 0]}
        speed={40} // Custom Speed from 1-99 - Default Speed: 40
        style={{ fontSize: "40px" }}
        wrapper="h2" // Animation will be rendered as a <span>
        repeat={0} // Repeat this Animation Sequence infinitely
      />
    </div>
  );
}

function Video(): JSX.Element {
  return (
    <video id="background-video" autoPlay loop muted>
      <source src={"/videos/charles.mp4"} type="video/mp4" />
    </video>
  );
}

function MyInterests(): JSX.Element {
  return (
    <div className="MyInterests">
      <div className="SectionTitle">
        <h1>Interests</h1>
      </div>
      <div className="ContactContainer">
        <p className="bigger NewMain-Container-Start">{data.top.description}</p>
      </div>
      <div className="Project-Container-Images">
        <div className="Project-Container-Images-All">
          {projects.projects[1].img.map((img, index) => {
            return <img className="Project-Image" key={index} src={img.link} />;
          })}
        </div>
        <p>
          <i>Scroll to view more →</i>
        </p>
      </div>
    </div>
  );
}

function Contact(): JSX.Element {
  return (
    <>
      <div className="SectionTitle">
        <h1>Contact</h1>
      </div>
      <div className="ContactContainer">
        <p className="bigger">
          <i>
            {contactdata.footer.contact.phrase}
            <a
              className="HyperLinks"
              href={"mailto:" + contactdata.footer.contact.email}
            >
              <i>
                <strong>{contactdata.footer.contact.email}</strong>
              </i>
            </a>
          </i>
        </p>
        <p className="bigger">
          <i>{contactdata.footer.cv.phrase}</i>
          <a className="HyperLinks" href={contactdata.footer.cv.link} download>
            <i>
              <strong>{contactdata.footer.cv.download_word} </strong>
            </i>
          </a>
        </p>
      </div>
    </>
  );
}

export function NewMain(): JSX.Element {
  return (
    <div className="NewMain-Container">
      <Video />
      <Charles />
      <div className="EmptyFullScreenDiv"></div>
      {/* <MyInterests />
      <Contact /> */}
    </div>
  );
}
